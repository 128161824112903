// Generated by Framer (2372734)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["SvTFVycgx"];

const variantClassNames = {SvTFVycgx: "framer-v-1bxk83b"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, level1, level2, width, ...props}) => { return {...props, FLkI95AHS: level1 ?? props.FLkI95AHS ?? true, H2N2BKx_h: level2 ?? props.H2N2BKx_h ?? "Level 1"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;level1?: boolean;level2?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, FLkI95AHS, H2N2BKx_h, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "SvTFVycgx", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-FyyKE", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-1bxk83b", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"SvTFVycgx"} ref={ref} style={{...style}}>{FLkI95AHS && (<RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7SW50ZXItNjAw", "--framer-font-size": "12px", "--framer-font-weight": "600", "--framer-letter-spacing": "0px", "--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-r6o4lv)"}}>Level 2</motion.p></React.Fragment>} className={"framer-1ewzw2r"} fonts={["GF;Inter-600"]} layoutDependency={layoutDependency} layoutId={"zYf4bVJ49"} style={{"--extracted-r6o4lv": "rgba(255, 255, 255, 0.8)", "--framer-link-hover-text-color": "#09F", "--framer-link-text-color": "var(--token-6a498dd2-32cd-4929-9ba2-df02fe315602, rgb(34, 34, 34))", "--framer-link-text-decoration": "none", "--framer-paragraph-spacing": "0px"}} text={H2N2BKx_h} verticalAlignment={"top"} withExternalLayout/>)}</motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-FyyKE [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-FyyKE .framer-1vi0czf { display: block; }", ".framer-FyyKE .framer-1bxk83b { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: min-content; }", ".framer-FyyKE .framer-1ewzw2r { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-FyyKE .framer-1bxk83b { gap: 0px; } .framer-FyyKE .framer-1bxk83b > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-FyyKE .framer-1bxk83b > :first-child { margin-left: 0px; } .framer-FyyKE .framer-1bxk83b > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 14.5
 * @framerIntrinsicWidth 39.5
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"FLkI95AHS":"level1","H2N2BKx_h":"level2"}
 */
const Framersuilqt77E: React.ComponentType<Props> = withCSS(Component, css, "framer-FyyKE") as typeof Component;
export default Framersuilqt77E;

Framersuilqt77E.displayName = "BreadCrumbItem - onDark";

Framersuilqt77E.defaultProps = {height: 14.5, width: 39.5};

addPropertyControls(Framersuilqt77E, {FLkI95AHS: {defaultValue: true, title: "Level 1", type: ControlType.Boolean}, H2N2BKx_h: {defaultValue: "Level 1", displayTextArea: false, title: "Level 2", type: ControlType.String}} as any)

addFonts(Framersuilqt77E, [{family: "Inter", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/suilqt77E:default", url: "https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuGKYMZhrib2Bg-4.ttf"}, style: "normal", url: "https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuGKYMZhrib2Bg-4.ttf", weight: "600"}])